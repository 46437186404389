$color-alert: #fc3737;
$color-blue-text: #0b0656;
$color-primary-gradient: linear-gradient(192.01deg, #44A9F4 -62.64%, #44A9F4 -62.62%, #0061AA 100%);
$color-card-membership: linear-gradient(225deg, #7CC244 0%, #0AA755 100%);

$color-primary: #0061AA;
$color-secondary: #7CC244;

$color-tertiary: #D43B1F;
$button-disabled: #bcbcbc;
$button-success: #5fd997;
$button-warning: #ffcf61;
$button-danger: #ff475d;